import { isNull } from 'lodash';
import { bool, boolean, number, object, string } from 'yup';
import dayjs from 'dayjs';
import {
  englishString,
  noCommaRegex,
  englishStringRegEx,
  isRequired,
  locationRegex,
  macAddressRegex,
  urlRegex,
  passwordString,
} from './common';

export const loginValidationSchema = object().shape({
  username: englishString.max(255).required('Username' + isRequired),
  password: englishString.max(255).required('Password' + isRequired),
});

export const vehicleValidationSchema = object({
  unit_number: englishString
    .matches(noCommaRegex, 'No commas accepted')
    .required('Unit' + isRequired),
  make: englishString.required('Make' + isRequired),
  model: englishString.required('Model' + isRequired),
  vin: string()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/,
      'English letters or numbers only'
    )
    .length(17, 'Length must be 17 characters')
    .required('VIN' + isRequired),
  plate: englishString.required('Plate#' + isRequired),
  license_plate_state: string().required(
    'License State Plate a is required field'
  ),
  fuel_type: string().required('Fuel Type' + isRequired),
  odometer_offset: number().required('Odometer Offset' + isRequired),
  odometer_offset_date_from: string().nullable(),
  odometer_offset_date_to: string()
    .nullable()
    .test(
      'smaller-from-date',
      'End date should be greater than start date',
      (value, context) => {
        const from = context.parent?.['odometer_offset_date_from'];
        const to = context.parent?.['odometer_offset_date_to'];
        console.log(from, to);
        if (isNull(from) || isNull(to)) return true;
        return dayjs(to).isAfter(dayjs(from));
      }
    ),
});

export const driverValidationSchema = object({
  name: englishString
    .matches(noCommaRegex, 'No commas accepted')
    .required('First Name' + isRequired),
  surname: englishString
    .required('Last Name' + isRequired)
    .matches(noCommaRegex, 'No commas accepted'),
  id_number: englishString.optional(),
  dl_number: englishString
    .required('Driver License Number' + isRequired)
    .matches(noCommaRegex, 'No commas accepted'),
  license_state: string().required('Driver license state' + isRequired),
  tg_link: string().matches(urlRegex, 'Provide a valid url address'),
  phone: string().matches(/^\d+$/, 'Numeric only').optional(),
  email: englishString
    .email('Email address must be a valid email')
    .required('Email address' + isRequired),
  username: englishString
    .required('Username' + isRequired)
    .matches(noCommaRegex, 'No commas accepted'),
  password: passwordString.required('Password' + isRequired),
});

export const fleetManagerValidationSchema = object({
  name: englishString
    .required('Name' + isRequired)
    .matches(noCommaRegex, 'No commas accepted'),
  surname: englishString
    .required('Surname' + isRequired)
    .matches(noCommaRegex, 'No commas accepted'),
  phone: string().matches(/^\d+$/, 'Numeric only').optional(),
  email: englishString
    .email('Email address must be a valid email')
    .required('Email address' + isRequired),
  username: englishString
    .required('Username' + isRequired)
    .matches(noCommaRegex, 'No commas accepted'),
  password: passwordString.required('Password' + isRequired),
});
export const profileValidationSchema = object({
  phone: string().matches(/^\d+$/, 'Numeric only').optional(),
});

export const accountsValidationSchema = object({
  name: englishString.required('Account Company name' + isRequired),
  phone: string()
    .matches(/^\d+$/, 'Numeric only')
    .required('Phone number' + isRequired),
  email: englishString
    .email('Email address must be a valid email')
    .required('Email address' + isRequired),
  address: englishString.required('Address line' + isRequired),
  city: englishString.required('City' + isRequired),
  state: englishString.required('State' + isRequired),
  postal_code: string()
    .matches(/^\d+$/, 'Numeric only')
    .required('Postal Code' + isRequired),
  account_name: englishString.required('Acount admin name' + isRequired),
  account_surname: englishString.required('Acount admin surname' + isRequired),
  account_username: englishString.required('Acount username' + isRequired),
  account_password: passwordString.required('Admin password' + isRequired),
  emd_account_name: englishString.required(
    'Account admin full name' + isRequired
  ),
  emd_account_username: englishString.required('Username' + isRequired),
  emd_account_password: passwordString.required('Password' + isRequired),
});

export const companyValidationSchema = object({
  name: englishString
    .required('Company name' + isRequired)
    .matches(noCommaRegex, 'No commas accepted'),
  phone: string().matches(/^\d+$/, 'Numeric only').optional(),
  email: string()
    .email('Email address must be a valid email')
    .matches(/^[A-Za-z0-9\s!@#$%^&*().,_+-]+$/, 'English letters only')
    .required('Email address' + isRequired),
  call_center: string().matches(/^\d+$/, 'Numeric only').optional(),
  billing_address: englishString.optional(),
  home_terminal_state: string().required('Home terminal state' + isRequired),
  home_terminal_postal_code: string()
    .matches(/^\d+$/, 'Numeric only')
    .required('Home terminal postal code' + isRequired),
  home_terminal_address: englishString.required(
    'Home terminal address' + isRequired
  ),
  home_terminal_city: englishString.required('Home terminal city' + isRequired),
  timezone: string().required('Timezone' + isRequired),
  dot_number: englishString.required('DOT number' + isRequired),
  mc_number: englishString.required('MC number' + isRequired),
  main_office_state: string().required('Main office state' + isRequired),
  main_office_city: englishString.required('Main office city' + isRequired),
  main_office_postal_code: string()
    .matches(/^\d+$/, 'Numeric only')
    .required('Main office postal code' + isRequired),
  main_office_address: englishString.required(
    'Main office address' + isRequired
  ),
  company_owner_name: englishString.required('Company owner name' + isRequired),
  company_owner_surname: englishString.required(
    'Company owner surname' + isRequired
  ),
  company_owner_username: englishString.required('Username' + isRequired),
  company_owner_password: passwordString.required('Password' + isRequired),
  subscription_plan: englishString.required('Subscription plans' + isRequired),
  allow_driver_edit_logs: bool(),
  allow_web_app_edit_logs: bool(),
  online_payment: bool().required('Required'),

  trusted: bool().test(
    'check-if-online-payment-is-enabled',
    'Online Payment should be checked to enable this field',
    (value, ctx) => {
      const onlinePayment = ctx.parent?.['online_payment'];
      return !(value && !onlinePayment);
    }
  ),
});

export const eldValidationSchema = object({
  id: englishString.required('ELD ID' + isRequired),
  type: englishString.required('ELD type' + isRequired),
  mac_address: string().matches(macAddressRegex, 'Provide a valid MAC address'),
});

export const vehicleTripReportValidationSchema = object({
  vehicle_id: englishString.required('Vehicle' + isRequired),
});

export const dvirReportValidationSchema = object({
  vehicle_id: englishString.required('Vehicle' + isRequired),
});

export const vehicleMovementReportValidationSchema = object({
  vehicle_id: englishString.required('Vehicle' + isRequired),
});

export const fmcsaReportValidationSchema = object({
  output_file_comment: englishString,
  driver_id: englishString.required('Driver' + isRequired),
  type: englishString.required('Type' + isRequired),
});

export const idlingReportValidationSchema = object({
  vehicle_id: englishString.required('Vehicle' + isRequired),
});

export const iftaReportValidationSchema = object({
  vehicle_id: englishString.required('Vehicle' + isRequired),
  state: englishString.required('State' + isRequired),
});

export const extendedIftaReportValidationSchema = object({
  vehicle_id: englishString.required('Vehicle' + isRequired),
});
export const logsReportValidationSchema = object({
  driver_id: englishString.required('Driver' + isRequired),
});

export const sendLogsReportValidationSchema = object({
  driver_id: englishString.required('Driver' + isRequired),
  type: englishString.required('Type' + isRequired),
  email: englishString
    .email('Email address must be a valid email')
    .required('Email' + isRequired),
});

export const purchaseSubscribtionValidationSchema = object({
  quantity: number().min(1),
  agreed: boolean(),
});

export function validateCoords(value: string | undefined) {
  if (!value) return false;
  const possibleCoords = value.split(',');
  const isValidCoords =
    possibleCoords.length === 2 &&
    possibleCoords.every((v) => !isNaN(Number(v)));
  if (isValidCoords) {
    return locationRegex.test(value); // Coordinate format
  } else return false;
}
export function validateLocationInput(value: string | undefined) {
  if (!value) return false;
  const possibleCoords = value.split(',');
  const isValidCoords =
    possibleCoords.length === 2 &&
    possibleCoords.every((v) => !isNaN(Number(v)));
  if (isValidCoords) {
    return locationRegex.test(value); // Coordinate format
  } else if (englishStringRegEx.test(value)) {
    return true; // English letters and special symbols
  } else {
    return false; // Neither coordinate nor string format
  }
}

export const DotValidationSchecma = object().shape({
  driver_id: number().required('Driver' + isRequired),
  report_date: string().required('Date of Inspection' + isRequired),
  date_from: string().required('Logs affected from' + isRequired),
  date_to: string().required('Logs affected to' + isRequired),
  location: string()
    .test('location-or-english', 'Invalid location', validateLocationInput)
    .required('Location is Required'),
});
